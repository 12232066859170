import React, { Component } from 'react'

import { useTranslation } from 'react-i18next'

import { keys, isObject } from 'lodash'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'
import { Modal, Button, Form, Input, Slider, Tooltip } from 'antd'

const formLayout = {
	labelCol: { span: 24 },
	wrapperCol: {
		span: 24,
	},
}

const marks = {
	1: 'Not at all',
	2: 'Partially',
	3: 'Highly',
}

export const ProjectModal = (props: any) => {
	const [form] = Form.useForm()
	const { t } = useTranslation()

	const handleFormSubmit = () => {
		console.log('handleFormSubmit')
		form.validateFields()
			.then((values) => {
				console.log('validated', values)
				props.onSave(values)
				props.onHide()
			})
			.catch((errorInfo) => {
				console.log('error', errorInfo)
			})
	}

	const footer = (
		<div style={{ display: 'flex' }}>
			<Button
				style={{ marginRight: 'auto' }}
				onClick={() => {
					props.onHide()
				}}
			>
				Cancel
			</Button>
			<Button onClick={handleFormSubmit} type="primary">
				Save
			</Button>
		</div>
	)

	return (
		<Modal
			visible={true}
			okButtonProps={{ style: { display: 'none' } }}
			onCancel={props.onHide}
			cancelText="Close"
			footer={footer}
		>
			<Form
				{...formLayout}
				form={form}
				onFinish={(values) => {
					props.onChange(props.id, values)
					props.onHide()
				}}
				initialValues={props.values}
			>
				<Form.Item
					name="name"
					required={true}
					label={t('models.project.fields.name')}
					rules={[
						{
							max: 50,
							message: 'Please shorten your title (50 characters max)',
						},
					]}
				>
					<Input placeholder={t('models.project.fields.name_explanation')}></Input>
				</Form.Item>
				<Form.Item
					name="description"
					label={t('models.project.fields.description')}
					rules={[
						{
							max: 80,
							message: 'Please shorten your goal (80 characters max)',
						},
					]}
				>
					<Input.TextArea placeholder={t('models.project.fields.description_explanation')}></Input.TextArea>
				</Form.Item>
				{false && (
					<Form.List name="indicators">
						{(fields, { add, remove }) => {
							return (
								<div>
									<div className="d-flex mb-2 align-items-center">
										<Button onClick={() => add()} className="mb-0 mr-4">
											<FontAwesomeIcon icon={faPlus} className="mr-1" />
											{t('models.indicator.action.add_x')}
										</Button>
									</div>

									{fields.map((field, index) => {
										return (
											<div key={index} className="d-flex mb-2">
												<Button className="mr-3 mb-0" onClick={() => remove(index)}>
													<FontAwesomeIcon icon={faTrash} className="mr-1" />
												</Button>

												<Form.Item name={[field.name, 'frequency']} className="mb-0">
													<Input />
												</Form.Item>
											</div>
										)
									})}

									{fields.length == 0 ? t('models.indicator.message.no_x') : null}
								</div>
							)
						}}
					</Form.List>
				)}
			</Form>
		</Modal>
	)
}

export default ProjectModal
