import React from 'react'

import { Motion, spring } from 'react-motion'
import { withTranslation } from 'react-i18next'

import Item from './Item'
import Radar from './Radar'
import { Amphi } from './Amphi'
import { keyBy, flatten } from 'lodash'
import { isItemInactive } from 'utils/rules'

import('./MainRadar.sass')

const springSetting1 = { stiffness: 180, damping: 10 }

export const radarOriginalSize = 560
export const radarBoundariesRatio = (225 * 2) / radarOriginalSize
export const radarHighToAverageBoundariesRatio = (100 * 2) / radarOriginalSize / radarBoundariesRatio
export const radarAverageToLowBoundariesRatio = (165 * 2) / radarOriginalSize / radarBoundariesRatio
export const radarLikelyToUndefinedBoundariesRatio = (275 * 2) / radarOriginalSize / radarBoundariesRatio

const springSettings = { stiffness: 70, damping: 30 }

class MainRadar extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			mouseXY: [0, 0],
			mouseCircleDelta: [0, 0], // difference between mouse and circle pos for x + y coords, for dragging
			lastPress: null, // key of the last pressed component
			isPressed: false,
			reload: 0,
		}

		this.radar = new Radar()
		this.startDragAt = null
	}

	componentDidMount() {
		window.addEventListener('touchmove', this.handleTouchMove)
		window.addEventListener('touchend', this.handleMouseUp)
		window.addEventListener('mousemove', this.handleMouseMove)
		window.addEventListener('mouseup', this.handleMouseUp)
	}

	componentWillUnmount() {
		window.removeEventListener('touchmove', this.handleTouchMove)
		window.removeEventListener('touchend', this.handleMouseUp)
		window.removeEventListener('mousemove', this.handleMouseMove)
		window.removeEventListener('mouseup', this.handleMouseUp)
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.scenario !== this.props.scenario) {
			this.props.setIsAmphiVisible(false)
		}
	}

	handleTouchStart = (key, e) => {
		e.stopPropagation()
		e.preventDefault()
		if (this.props.isAmphiVisible) {
			this.props.setIsAmphiVisible(false)
		} else {
			this.handleMouseDown(key, e.touches[0])
		}
	}

	handleTouchMove = (e) => {
		e.stopPropagation()
		e.preventDefault()
		this.handleMouseMove(e.touches[0])
	}

	handleMouseUp = (e) => {
		e.stopPropagation()
		e.preventDefault()

		let timeDiff = Date.now() - this.startDragAt
		if (!this.hasMoved || timeDiff < 150) {
			if (this.state.lastPress) this.props.setIsAmphiVisible(true)
			this.setState({
				isPressed: false,
				lastPress: null,
				mouseCircleDelta: [0, 0],
				mouseXY: [0, 0],
			})

			return
		}
		this.hasMoved = false
		this.startDragAt = null

		const {
			lastPress,
			isPressed,
			mouseCircleDelta: [dx, dy],
		} = this.state

		if (lastPress !== null) {
			let coords = this.radar.normalizeCoordinates(
				this.props.height,
				this.props.height,
				this.props.offsetX,
				this.props.offsetY,
				e.pageX,
				e.pageY
			)
			coords[0] = Math.max(-125, coords[0])
			coords[0] = Math.min(125, coords[0])
			coords[1] = Math.max(-125, coords[1])
			coords[1] = Math.min(125, coords[1])

			//console.log('handleMouseUp Coordinates', coords);
			this.props.onChangeItemPosition(this.state.lastPress, coords[0], coords[1])
		}

		this.setState({ isPressed: false, lastPress: null, mouseCircleDelta: [0, 0], mouseXY: [0, 0] })
		setTimeout(() => {
			this.props.select(null)
		}, 10)
	}

	handleMouseMove = (e) => {
		e.preventDefault()
		e.stopPropagation()

		const {
			lastPress,
			isPressed,
			mouseCircleDelta: [dx, dy],
		} = this.state

		if (isPressed) {
			this.hasMoved = true
			//console.log('handleMouseMove', { pageX, pageY }, this.state.mouseCircleDelta);
			const mouseXY = [e.pageX - dx, e.pageY - dy]
			this.setState({ mouseXY })
			this.props.setIsAmphiVisible(false)
		}
	}

	handleMouseDown = (key, e) => {
		e.preventDefault()
		e.stopPropagation()

		// Current denormalized coordinates
		this.props.select(this.props.children[key])
		if (!this.props.mode || this.props.mode.name != 'initial') return

		//console.log(this.props.select);

		let [dx, dy] = this.radar.denormalizeCoordinates(
			this.props.height,
			this.props.height,
			this.props.offsetX,
			this.props.offsetY,
			this.props.children[key].x,
			this.props.children[key].y
		)
		this.hasMoved = false
		this.startDragAt = Date.now()

		const mouseXY = [e.pageX, e.pageY]
		const mouseCircleDelta = [dx - e.pageX, dy - e.pageY]

		//console.log('handleMouseDown', key, pressX, pressY, pageX, pageY);
		this.setState({
			lastPress: key,
			isPressed: true,
			mouseCircleDelta,
			mouseXY: mouseXY,
		})
	}

	render() {
		const { order, lastPress, isPressed, mouseXY } = this.state
		const { width, height, t } = this.props
		const indexedChildren = keyBy(this.props.children, 'id')

		let shouldTransition = this.props.isTransitioning && !isPressed

		const targetLinks = this.props.selection
			? flatten(this.props.children.map((c) => c.links)).filter((l) => {
					return l.originId == this.props.selection.id || l.targetId == this.props.selection.id
			  })
			: this.props.displayAllLinks
			? flatten(this.props.children.map((c) => c.links))
			: []

		let highlightedItems = {}
		if (this.props.selection) {
			highlightedItems[this.props.selection.id] = true

			targetLinks.forEach((l) => {
				highlightedItems[l.targetId] = true
				highlightedItems[l.originId] = true
			})
		}

		//console.log('Radar children', this.props.children)

		let amphiCoordinates = this.props.selection
			? this.radar.denormalizeCoordinates(
					this.props.height,
					this.props.height,
					0,
					0,
					this.props.selection?.x,
					this.props.selection?.y
			  )
			: [null, null]

		//console.log('amphiCoordinates', amphiCoordinates, this.props.isAmphiVisible)

		return (
			<div
				id="MainRadar"
				style={{
					height: this.props.height + 'px',
					width: this.props.width + 'px',
					marginTop: this.props.marginTop + 'px',
				}}
			>
				<Amphi
					key={this.props.selection?.id || 'empty'}
					x={amphiCoordinates[0]}
					y={amphiCoordinates[1]}
					visible={this.props.isAmphiVisible}
					onChange={this.props.onChange}
					onDismiss={() => {
						//console.log('dismiss')
						this.props.setIsAmphiVisible(false)
					}}
					selection={this.props.selection}
				></Amphi>
				<svg
					id="vectorialRadar"
					viewBox="0 0 560 560"
					width={height + 'px'}
					height={height + 'px'}
					onClick={() =>
						this.props.select(null, () => {
							//console.log('remove amphi')
							this.props.setIsAmphiVisible(false)
						})
					}
				>
					<defs>
						<mask id="positive">
							<rect width="100%" height="50%" x="0" y="0" fill="white"></rect>
						</mask>
						<mask id="negative">
							<rect width="100%" height="50%" x="0" y="280" fill="white"></rect>
						</mask>

						<path id="externalTopPath" d="M115,222 a115,115 0 0,1 330,0"></path>
						<path id="externalBottomPath" d="M115,388 a115,115 0 0,0 330,0"></path>
						<path id="internalTopPath" d="M115,280 a115,115 0 0,1 330,0"></path>
						<path id="internalBottomPath" d="M115,280 a115,115 0 0,0 330,0"></path>
						<linearGradient
							id="greenToRed"
							gradientUnits="userSpaceOnUse"
							x1="376.5"
							y1="61.2857"
							x2="376.5"
							y2="691.6642"
						>
							<stop offset="50%" style={{ stopColor: '#24CCB8' }} />
							<stop offset="50%" style={{ stopColor: '#E70549' }} />
						</linearGradient>
					</defs>

					<circle className="circle2" r="165" cx="280" cy="280"></circle>
					<circle className="circle1" r="100" cx="280" cy="280"></circle>

					<g className="circle3">
						<g transform={`scale(${radarOriginalSize / 755})`}>
							<path
								fill="url(#greenToRed)"
								d="M681.5,376.5C681.5,210,548,74.7,382.2,71.6c0.7,0,1.5,0,2.2,0l-7.9-10.3l-7.9,10.3c0.7,0,1.5,0,2.2,0
		C205,74.7,71.5,210,71.5,376.5c0,165.8,132.3,300.7,297.1,304.9l7.9,10.3l7.9-10.3C549.2,677.2,681.5,542.3,681.5,376.5z
		 M382.9,677.9l-2.7,3.5l0,0l-3.6,4.8l-3.6-4.8l0,0l-2.7-3.5C206.6,674.5,75,540.9,75,376.5c0-164.4,131.5-298,295.1-301.4l2.7-3.5
		l0,0l3.7-4.8l3.7,4.8l0,0l2.7,3.5C546.5,78.5,678,212.1,678,376.5C678,540.9,546.4,674.5,382.9,677.9z"
							/>
						</g>
					</g>

					<text dy="-18px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="1px">
						<textPath className="noselection" xlinkHref="#externalBottomPath" startOffset="50%">
							{t('models.item.activeness_zones.external_inner_negative')}
						</textPath>
					</text>
					<text dy="-18px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="1px">
						<textPath className="noselection" xlinkHref="#externalBottomLeftPath" startOffset="50%">
							{t('models.item.activeness_zones.external_inner_negative')}
						</textPath>
					</text>
					<text dy="-18px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="1px">
						<textPath className="noselection" xlinkHref="#externalBottomRightPath" startOffset="50%">
							{t('models.item.activeness_zones.external_inner_negative')}
						</textPath>
					</text>

					<text dy="-21.5px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="-0.6px">
						<textPath className="noselection" xlinkHref="#externalTopPath" startOffset="50%">
							{t('models.item.activeness_zones.external_inner_positive')}
						</textPath>
					</text>
					<text dy="-21.5px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="-0.6px">
						<textPath className="noselection" xlinkHref="#internalTopPath" startOffset="50%">
							{t('models.item.activeness_zones.internal_low_positive')}
						</textPath>
					</text>
					<text dy="39.5px" style={{ fill: 'rgba(255,255,255,0.15)' }} letterSpacing="2.5px">
						<textPath className="noselection" xlinkHref="#internalTopPath" startOffset="50%">
							{t('models.item.activeness_zones.internal_medium_positive')}
						</textPath>
					</text>
					<text
						className="noselection"
						dy={this.props.isDecisions ? '-40' : '-20'}
						x="280"
						y="280"
						style={{ fill: 'rgba(255,255,255,0.16)' }}
					>
						{t('models.item.activeness_zones.internal_high_positive')}
					</text>
					<text dy="35px" style={{ fill: 'rgba(255,255,255,0.14)' }} letterSpacing="-0.7px">
						<textPath className="noselection" xlinkHref="#internalBottomPath" startOffset="50%">
							{t('models.item.activeness_zones.internal_low_negative')}
						</textPath>
					</text>
					<text dy="-26.5px" style={{ fill: 'rgba(255,255,255,0.15)' }} letterSpacing="3px">
						<textPath
							className="noselection"
							xlinkHref="#internalBottomPath"
							startOffset="50%"
							letterSpacing="2.4px"
						>
							{t('models.item.activeness_zones.internal_medium_negative')}
						</textPath>
					</text>
					<text
						className="noselection"
						dy={this.props.isDecisions ? '50' : '30'}
						x="280"
						y="280"
						style={{ fill: 'rgba(255,255,255,0.16)' }}
					>
						{t('models.item.activeness_zones.internal_high_negative')}
					</text>
					<line className="horizon" x1="51" x2="509" y1="280" y2="280"></line>
					<line className="horizon outside" x1="40" x2="51" y1="280" y2="280"></line>
					<line className="horizon outside" x1="509" x2="520" y1="280" y2="280"></line>

					{targetLinks
						? targetLinks.map((l, index) => {
								if (!indexedChildren[l.originId] || !indexedChildren[l.targetId]) return null

								let reversed = this.props.selection && l.originId !== this.props.selection.id
								let originCoords = [indexedChildren[l.originId].x, indexedChildren[l.originId].y]
								let targetCoords = [indexedChildren[l.targetId].x, indexedChildren[l.targetId].y]

								if (
									this.props.selection &&
									this.state.lastPress !== null &&
									(l.originId === this.props.selection.id ||
										l.targetId === this.props.selection.id) &&
									this.state.isPressed
								) {
									let [x, y] = this.state.mouseXY

									x += this.state.mouseCircleDelta[0]
									y += this.state.mouseCircleDelta[1]

									if (reversed) {
										targetCoords = this.radar.normalizeCoordinates(
											this.props.height,
											this.props.height,
											this.props.offsetX,
											this.props.offsetY,
											x,
											y
										)
									} else {
										originCoords = this.radar.normalizeCoordinates(
											this.props.height,
											this.props.height,
											this.props.offsetX,
											this.props.offsetY,
											x,
											y
										)
									}
								}

								let coords1 = this.radar.denormalizeCoordinates(
									radarOriginalSize,
									radarOriginalSize,
									0,
									0,
									originCoords[0],
									originCoords[1]
								)

								let coords2 = this.radar.denormalizeCoordinates(
									radarOriginalSize,
									radarOriginalSize,
									0,
									0,
									targetCoords[0],
									targetCoords[1]
								)

								const lineStyle = {
									x1: shouldTransition ? spring(coords1[0], springSettings) : coords1[0],
									x2: shouldTransition ? spring(coords2[0], springSettings) : coords2[0],
									y1: shouldTransition ? spring(coords1[1], springSettings) : coords1[1],
									y2: shouldTransition ? spring(coords2[1], springSettings) : coords2[1],
								}

								return [
									<Motion key={l.id + 'l1'} style={lineStyle}>
										{({ x1, x2, y1, y2 }) => (
											<line
												className={[
													'linkBackground',
													originCoords[1] > 5 ? 'positive' : '',
													originCoords[1] < -5 ? 'negative' : '',
													originCoords[1] <= 5 && originCoords[1] >= -5 ? 'neutral' : '',
												].join(' ')}
												onClick={() => this.props.onDeleteLink(l.originId, l.id)}
												strokeWidth={l.strength}
												x1={x1}
												x2={x2}
												y1={y1}
												y2={y2}
											></line>
										)}
									</Motion>,
									<Motion key={l.id + 'l2'} style={lineStyle}>
										{({ x1, x2, y1, y2 }) => {
											const isInactive = isItemInactive({
												x: originCoords[0],
												y: originCoords[1],
											})
											return (
												<line
													key={index + 'f'}
													className={[
														'link',
														originCoords[1] > 5 && !isInactive ? 'positive' : '',
														originCoords[1] < -5 && !isInactive ? 'negative' : '',
														isInactive ? 'neutral' : '',
													].join(' ')}
													onClick={() => this.props.onDeleteLink(l.originId, l.id)}
													stroke={'rgb(0, 192, 255)'}
													strokeWidth={l.strength}
													strokeDasharray={['', '2, 8', '5, 4', '10 1'][l.strength]}
													x1={x1}
													x2={x2}
													y1={y1}
													y2={y2}
												></line>
											)
										}}
									</Motion>,
								]
						  })
						: null}
				</svg>
				<div
					className="Items"
					style={{
						height: '0px',
						width: '0px',
					}}
				>
					{this.props.children.map((c, index) => {
						let style
						let x = c.x
						let y = c.y
						let selected = c.id == this.props.selection?.id

						if (index === lastPress && isPressed) {
							;[x, y] = mouseXY

							selected = true
							x += this.state.mouseCircleDelta[0]
							y += this.state.mouseCircleDelta[1]
							style = {
								translateX: x - this.props.offsetX,
								translateY: y - this.props.offsetY,
								scale: spring(1.2, springSetting1),
							}
						} else {
							let coords = this.radar.denormalizeCoordinates(
								this.props.height,
								this.props.height,
								0,
								0,
								x,
								y
							)
							x = coords[0]
							y = coords[1]

							/*console.log(
							'node',
							coords[0],
							coords[1],
							'<=',
							this.props.height,
							this.props.height,
							this.props.offsetX,
							this.props.offsetY
						)*/

							style = {
								translateX: shouldTransition ? spring(x, springSettings) : x,
								translateY: shouldTransition ? spring(y, springSettings) : y,
								scale: spring(1, springSetting1),
							}
						}

						//console.log('x', x, 'y', y);
						return (
							<Motion key={c.id} style={style}>
								{({ translateX, translateY, scale }) => (
									<Item
										key={c.id}
										{...c}
										selected={selected}
										fade={this.props.selection && !highlightedItems[c.id]}
										onMouseDown={this.handleMouseDown.bind(this, index)}
										onTouchStart={this.handleTouchStart.bind(this, index)}
										style={{
											WebkitTransform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
											transform: `translate3d(${translateX}px, ${translateY}px, 0) scale(${scale})`,
											zIndex: index === lastPress ? 99 : 1,
										}}
									></Item>
								)}
							</Motion>
						)
					})}
				</div>
			</div>
		)
	}
}

export default withTranslation()(MainRadar)
