//@ts-ignore
import * as React from 'react'
import { getScoreColor, vulnerability } from 'utils/ranking'
import { getItemAlteredByScenario, isItemInactive, isItemNeutral } from 'utils/rules'

import './RadialVulnerabilityGauge.sass'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown, faSadCry, faMeh } from '@fortawesome/pro-light-svg-icons'

const RadialVulnerabilityGauge = (props) => {
	let originalStakefactors = props.stakefactors.map((s) => s.o || s)
	let alteredStakefactors = props.stakefactors.map((s) => getItemAlteredByScenario(s, props.scenario))

	const currentVulnerability = vulnerability(alteredStakefactors)
	const color =
		100 - currentVulnerability <= 33 ? '#E70549' : 100 - currentVulnerability <= 66 ? '#FFFFFF99' : '#00B0A9'
	const arcLength = 2 * Math.PI * 20

	let countPositive = alteredStakefactors.filter((s) => s.y > 5 && !isItemInactive(s)).length
	let countNeutral = alteredStakefactors.filter((s) => isItemNeutral(s)).length
	let countNegative = alteredStakefactors.filter((s) => s.y < -5 && !isItemInactive(s)).length

	return (
		<svg className="RadialVulnerabilityGauge noselection" viewBox="-10 -25 120 90">
			<g transform="scale(1.1)  translate(-5 2)">
				<path d="M 10 25 A 15 15 0 0 1 90 25" stroke="grey" strokeWidth="12" fill="none"></path>
				<path
					d="M 10 25 A 15 15 0 0 1 90 25"
					stroke={color}
					strokeDasharray={((100 - currentVulnerability) / 100) * arcLength + ' ' + arcLength}
					strokeWidth="12"
					fill="none"
					className="arc"
				></path>
			</g>

			<g transform="translate(0 -30)">
				<text x="50" y="48" textAnchor="middle" fill="#696969" fontSize="8">
					Alignment
				</text>
				<text x="50" y="59" textAnchor="middle" fill="#696969" fontSize="8">
					Score
				</text>
				<g transform="translate(0 90)">
					<foreignObject>
						<FontAwesomeIcon color="white" icon={faMeh}></FontAwesomeIcon>
					</foreignObject>
					<text className="npsItem negative" fontSize="6" dx="-10">
						Detractors
					</text>
					<text className="npsItem neutral" textAnchor="middle" dx="50.5" fontSize="6">
						Neutrals
					</text>
					<text className="npsItem positive" dx="80" fontSize="6">
						Supporters
					</text>
					<g transform="scale(0.15) translate(-55 -140)">
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fal"
							data-icon="frown"
							className="svg-inline--fa fa-frown fa-w-16 negativeIcon"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 496 512"
							color="white"
						>
							<path
								fill="#E70549"
								d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm0-152c-44.4 0-86.2 19.6-114.8 53.8-5.7 6.8-4.8 16.9 2 22.5 6.8 5.7 16.9 4.8 22.5-2 22.4-26.8 55.3-42.2 90.2-42.2s67.8 15.4 90.2 42.2c5.3 6.4 15.4 8 22.5 2 6.8-5.7 7.7-15.8 2-22.5C334.2 339.6 292.4 320 248 320zm-80-80c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
							></path>
						</svg>
						<text fontSize="70" dy="70" dx="120" fill="#E70549">
							{countNegative}
						</text>
					</g>
					<g transform="scale(0.15) translate(240 -140)">
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fal"
							data-icon="meh"
							className="svg-inline--fa fa-meh fa-w-16 "
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 496 512"
							color="white"
						>
							<path
								fill="#FFFFFF99"
								d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm-80-232c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160-64c-17.7 0-32 14.3-32 32s14.3 32 32 32 32-14.3 32-32-14.3-32-32-32zm16 160H152c-8.8 0-16 7.2-16 16s7.2 16 16 16h192c8.8 0 16-7.2 16-16s-7.2-16-16-16z"
							></path>
						</svg>
						<text fontSize="70" dy="70" dx="120" fill="#FFFFFF99">
							{countNeutral}
						</text>
					</g>
					<g transform="scale(0.15) translate(540 -140)">
						<svg
							aria-hidden="true"
							focusable="false"
							data-prefix="fal"
							data-icon="smile"
							className="svg-inline--fa fa-smile fa-w-16 positiveIcon"
							role="img"
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 496 512"
							color="white"
						>
							<path
								fill="#00B0A9"
								d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 464c-119.1 0-216-96.9-216-216S128.9 40 248 40s216 96.9 216 216-96.9 216-216 216zm90.2-146.2C315.8 352.6 282.9 368 248 368s-67.8-15.4-90.2-42.2c-5.7-6.8-15.8-7.7-22.5-2-6.8 5.7-7.7 15.7-2 22.5C161.7 380.4 203.6 400 248 400s86.3-19.6 114.8-53.8c5.7-6.8 4.8-16.9-2-22.5-6.8-5.6-16.9-4.7-22.6 2.1zM168 240c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32zm160 0c17.7 0 32-14.3 32-32s-14.3-32-32-32-32 14.3-32 32 14.3 32 32 32z"
							></path>
						</svg>
						<text fontSize="70" dy="70" dx="120" fill="#00B0A9">
							{countPositive}
						</text>
					</g>
				</g>
			</g>
		</svg>
	)
}

export default RadialVulnerabilityGauge
