import { colorToPercent } from 'utils/ranking'

export const getItemAlteredByScenario = (item, scenario) => {
	if (!scenario?.id) return item

	let sc = item.scenarios ? JSON.parse(item.scenarios) : {}
	let attributes = sc && sc[scenario?.id] ? sc[scenario?.id] : {}

	return {
		...item,
		x: attributes.x !== undefined ? attributes.x : item.x,
		y: attributes.y !== undefined ? attributes.y : item.y,
		trend: attributes.trend !== undefined ? attributes.trend : item.trend,
		impact: attributes?.impact !== undefined ? attributes?.impact : item?.impact,
		o: {
			...item,
		},
	}
}

export const vulnerabilityConstraints = function (constraints) {
	//console.log('-------- vulnerability', items);

	if (!constraints || constraints.length == 0) {
		return null
	}

	var total_weight = 0
	var total = 0

	for (var i = 0; i < constraints.length; i++) {
		total_weight += constraints[i].weight || 1

		total += colorToPercent(constraints[i].trend) * (constraints[i].weight || 1)
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50

	return currentPercentage
}

export const vulnerabilityIndicators = function (indicators) {
	//console.log('-------- vulnerability', items);

	if (!indicators || indicators.length == 0) {
		return null
	}

	var total_weight = 0
	var total = 0

	for (var i = 0; i < indicators.length; i++) {
		total_weight += indicators[i].weight || 1

		total += colorToPercent(indicators[i].trend) * (indicators[i].weight || 1)
	}

	let currentPercentage = total_weight != 0 ? total / total_weight : 50

	return currentPercentage
}

export const isItemInactive = (item) => {
	if (!item) return false

	if (item.y <= 5 && item.y >= -5) return true

	const distanceFromCenter = Math.sqrt(Math.pow(item.x, 2) + Math.pow(item.y, 2))
	const isOutside = distanceFromCenter > 100

	return isOutside
}

export const isItemNeutral = (item) => {
	if (!item) return false

	if (item.y <= 5 && item.y >= -5) return true

	return false
}
