import React, { useCallback, useEffect } from 'react'

import { Switch, Route } from 'react-router-dom'
import { useHistory, useParams, useRouteMatch } from 'react-router'

import { useHotkeys } from 'react-hotkeys-hook'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/pro-light-svg-icons'
import { Button, Input, Select } from 'antd'

import Loader from 'components/Loading/Loader'
import ProjectModal from 'components/Modal/ProjectModal'
import ProjectNavigation from 'components/navigation/ProjectNavigation'

import SubHeaderPortal from '../../layout/header/SubHeaderPortal'

import {
	useGetProjectByOriginQuery,
	useUpdateProjectMutation,
	useUpdateStakefactorMutation,
} from '../../graphql/generated'

import Navigator from './Navigator/Navigator'

import './Project.sass'

let lastData: any = null

export function Project(props: any) {
	useEffect(() => {
		console.log('Project useEffect')
		if (props.setExpanded) props.setExpanded(true)
	}, [])

	let history = useHistory()
	const params: any = useParams()
	const [editedItem, setEditedItem] = React.useState<any | null>(null)
	const [searchTerm, setSearchTerm] = React.useState<any | null>('')
	const [currentScenarioIndex, setCurrentScenarioIndex] = React.useState<number | null>(null)

	const [updateData] = useUpdateProjectMutation()

	const { data, refetch, loading } = useGetProjectByOriginQuery({
		variables: { id: params?.id },
	})
	const _refetch = useCallback(() => {
		setTimeout(() => refetch(), 0)
	}, [refetch])

	// Update project
	const handleUpdateProject = async (id: any, values: any) => {
		const result = await updateData({
			variables: {
				id: id,
				...values,
			},
		})

		await _refetch()
	}

	const onGoNext = (url: string) => {
		history.push(url)
	}

	if (data) {
		lastData = data
	}

	const projectData = data || lastData
	const portalContent = (
		<>
			<div>
				<div className="Title">
					{projectData?.getProjectByOrigin?.name
						? projectData?.getProjectByOrigin?.name
						: projectData?.getProjectByOrigin
						? 'No name'
						: ''}
				</div>
				<div className="SubTitle">
					<small>
						{projectData?.getProjectByOrigin?.description
							? projectData?.getProjectByOrigin?.description
							: projectData?.getProjectByOrigin
							? null
							: null}
					</small>
				</div>
			</div>

			<FontAwesomeIcon
				style={{ marginLeft: '0.5rem', fontSize: '1.2rem', cursor: 'pointer' }}
				icon={faEdit}
				onClick={() => setEditedItem(projectData?.getProjectByOrigin)}
			/>
		</>
	)

	const currentScenario =
		currentScenarioIndex !== null ? projectData?.getProjectByOrigin?.scenarios[currentScenarioIndex] : null

	const handleGoToPreviousScenario = () => {
		console.log('handleGoToPreviousScenario', projectData)
		if (currentScenarioIndex === 0 && projectData?.getProjectByOrigin?.scenarios?.length > 0) {
			setCurrentScenarioIndex(null)
		} else if (currentScenarioIndex > 0) {
			setCurrentScenarioIndex(currentScenarioIndex - 1)
		}
	}

	const handleGoToNextScenario = () => {
		console.log('handleGoToNextScenario', projectData)
		if (currentScenarioIndex === null && projectData?.getProjectByOrigin?.scenarios?.length > 0) {
			setCurrentScenarioIndex(0)
		} else if (currentScenarioIndex === null) {
		} else if (currentScenarioIndex < projectData?.getProjectByOrigin?.scenarios?.length - 1) {
			setCurrentScenarioIndex(currentScenarioIndex + 1)
		}
	}

	// Change scenario with arrow keys
	useHotkeys('ArrowRight', handleGoToNextScenario)
	useHotkeys('ArrowLeft', handleGoToPreviousScenario)

	const handleGoToScenario = (index) => {
		setCurrentScenarioIndex(index)
	}

	const searchBar = (
		<Input
			onChange={(e) => {
				setSearchTerm(e.target.value)
			}}
			value={searchTerm}
			style={{ marginLeft: 'auto', marginRight: '1em' }}
			placeholder="Search"
			className="SearchInput"
		></Input>
	)

	return (
		<div className={['Project', ''].join(' ')}>
			<SubHeaderPortal>
				<ProjectNavigation
					{...props}
					onExpand={() => {
						props.setExpanded(true)
					}}
					onShrink={() => {
						props.setExpanded(false)
					}}
					expanded={false}
					setExpanded={props.setExpanded}
					project={projectData?.getProjectByOrigin}
					scenario={currentScenario}
					scenarioIndex={currentScenarioIndex}
					scenarioCount={projectData?.getProjectByOrigin?.scenarios?.length}
					onGoToPreviousScenario={handleGoToPreviousScenario}
					onGoToNextScenario={handleGoToNextScenario}
					onGoToScenario={handleGoToScenario}
					onUpdate={handleUpdateProject}
					{...projectData?.getProjectByOrigin}
					loading={loading}
				></ProjectNavigation>
			</SubHeaderPortal>

			{loading ? (
				<Loader></Loader>
			) : (
				<>
					<Switch>
						<Route key="navigator" exact path="/:id">
							<Navigator
								{...projectData?.getProjectByOrigin}
								refetch={_refetch}
								{...props}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								expanded={true}
								scenario={currentScenario}
								scenarios={projectData?.getProjectByOrigin?.scenarios || []}
								portalContent={
									<>
										{portalContent}
										{searchBar}
									</>
								}
							/>
						</Route>
					</Switch>
					{props.children}
				</>
			)}
			{editedItem && (
				<ProjectModal
					onHide={() => {
						setEditedItem(null)
					}}
					onSave={(values: Object) => {
						handleUpdateProject(editedItem.id, values)
						setEditedItem(null)
					}}
					id={editedItem?.id}
					values={{
						name: editedItem?.name,
						description: editedItem?.description,
					}}
				></ProjectModal>
			)}
		</div>
	)
}
