import React, { Component } from 'react'
import { NavBar } from './components/navigation/NavBar'
import { Router, Route, Switch } from 'react-router-dom'
import { useTranslation, initReactI18next } from 'react-i18next'

import SelectionContext from './components/Utils/SelectionContext'
import SelectionProvider from './components/Utils/SelectionProvider'
import ModalContext from './components/Utils/ModalContext'
import ModalProvider from './components/Utils/ModalProvider'
import SubscriptionProvider from './components/Utils/SubscriptionProvider'
import SubscriptionContext from './components/Utils/SubscriptionContext'

import { Layout } from 'antd'
import { Profile } from './pages/profile/Profile'
import { Projects } from './pages/Projects/Projects'
import { Project } from './pages/Project/Project'

import { history } from './utils/history'
import Header from './layout/header/Header'

import { PrivateRoute } from './components/navigation/PrivateRoute'

import 'bootstrap/dist/css/bootstrap.css'

import 'antd/dist/antd.css'

import './App.sass'
import { ThankYou } from 'pages/thankyou/ThankYou'

const SelectionConsumer = SelectionContext.Consumer
const ModalConsumer = ModalContext.Consumer
const SubscriptionConsumer = SubscriptionContext.Consumer

interface Props {
	returnTo: string
	paymentPublicKey: string
}
interface State {}

export default class App extends Component<Props, State> {
	state = {
		selection: null,
		isExpanded: true,
	}

	render() {
		const forceHeader = false

		return (
			<div id="App" className={['dark', 'expanded'].join(' ')}>
				<Router history={history}>
					<SubscriptionProvider>
						<SelectionProvider>
							<ModalProvider>
								<SubscriptionConsumer>
									{(subscriptionProps) => (
										<SelectionConsumer>
											{(selectionProps) => (
												<Layout
													className={'Layout'}
													style={{ paddingTop: this.state.isExpanded ? '0px' : '88px' }}
												>
													<Header visible={!this.state.isExpanded || forceHeader}></Header>
													<Layout.Content>
														<ModalConsumer>
															{(modalProps) => (
																<Switch>
																	<PrivateRoute key="profile" path="/profile">
																		<Profile
																			paymentPublicKey={
																				this.props.paymentPublicKey
																			}
																			returnTo={this.props.returnTo}
																			{...subscriptionProps}
																		></Profile>
																	</PrivateRoute>
																	<PrivateRoute key="project" path="/:id">
																		<Project
																			{...modalProps}
																			{...selectionProps}
																			{...subscriptionProps}
																			expanded={this.state.isExpanded}
																			setExpanded={(value) => {
																				this.setState({ isExpanded: value })
																			}}
																		></Project>
																	</PrivateRoute>
																	<PrivateRoute key="thankyou" path="/thankyou">
																		<ThankYou
																			{...modalProps}
																			{...subscriptionProps}
																		></ThankYou>
																	</PrivateRoute>

																	<PrivateRoute
																		key="projectsRoot"
																		exact={true}
																		path=""
																	>
																		<Projects
																			{...modalProps}
																			{...subscriptionProps}
																		></Projects>
																	</PrivateRoute>
																</Switch>
															)}
														</ModalConsumer>

														<ModalConsumer>
															{({ modals, popModal }) => {
																return modals.map((m, index) => {
																	return React.cloneElement(m, {
																		key: m.key || index,
																		show: true,
																		visible: true,
																		onHide: popModal,
																	})
																})
															}}
														</ModalConsumer>
													</Layout.Content>
												</Layout>
											)}
										</SelectionConsumer>
									)}
								</SubscriptionConsumer>
							</ModalProvider>
						</SelectionProvider>
					</SubscriptionProvider>
				</Router>
				<div id="copyright">
					© {new Date().getFullYear()} Visto&Vista Ltd{' '}
					<small> - {process.env.REACT_APP_BUILD_TIME || 'dev'}</small>
				</div>
			</div>
		)
	}
}
