import React from 'react'
import PropTypes from 'prop-types'

import './Legend.sass'

function Legend(props) {
	return (
		<svg
			id="Legend"
			viewBox="75 0 80 560"
			style={{
				height: props.height + 'px',
				width: '150px',
				marginTop: props.marginTop + 'px',
			}}
		>
			<g className="Legend noselection">
				<g className="positive" transform={'translate( -135, ' + 560 / 2 + ' ) rotate(-90 0 0)'}>
					<text x="20" y={560 / 2 - 35}>
						Favorable
					</text>
					<text x="20" y={560 / 2 + 2}>
						Zone
					</text>
				</g>
				<g className="neutral" transform={'translate( 0, ' + 46 + ' ) '}>
					<text x="80" y={560 / 2 - 40}>
						Neutral
					</text>
				</g>
				<g className="negative" transform={'translate( -135, ' + 560 / 2 + ' ) rotate(-90 0 0)'}>
					<text x="-20" y={560 / 2 - 35}>
						Hostile
					</text>
					<text x="-20" y={560 / 2 + 2}>
						Zone
					</text>
				</g>
			</g>
		</svg>
	)
}

Legend.propTypes = {}

export default Legend
