import React, { Component } from 'react'

import SubscriptionContext from './SubscriptionContext'

export default class SubscriptionProvider extends Component {
	setSubscriptionDetails = (subscriptionDetails) => {
		this.setState({
			subscriptionDetails,
			isSubscriber: subscriptionDetails && subscriptionDetails.status === 'active',
			maxProjectsCount: subscriptionDetails && subscriptionDetails.projects ? subscriptionDetails.projects : 1,
		})
	}

	state = {
		subscriptionDetails: null,
		isSubscriber: false,
		setSubscriptionDetails: this.setSubscriptionDetails,
	}

	render() {
		return <SubscriptionContext.Provider value={this.state}>{this.props.children}</SubscriptionContext.Provider>
	}
}
