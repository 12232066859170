import React, { Component } from 'react'

import { findIndex, keyBy, flatten, uniq, compact } from 'lodash'
import { useParams } from 'react-router'
import { useTranslation } from 'react-i18next'
import gql from 'graphql-tag'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons'

import MainRadar from 'components/Radar/MainRadar'
import VulnerabilityScale from 'components/Radar/VulnerabilityScale'
import ResponsiveWrapper from 'components/Utils/ResponsiveWrapper'
import { SummaryColumn } from './SummaryColumn/SummaryColumn'

// Help
import TutorialPopover from 'components/Help/TutorialPopover'
import { getNextStep, getInitialStep } from 'help/navigator'

import {
	useCreateStakefactorMutation,
	useUpdateStakefactorPositionMutation,
	useUpdateStakefactorIconMutation,
	useUpdateStakefactorImpactMutation,
	useUpdateStakefactorTagsMutation,
	useUpdateStakefactorTrendMutation,
	useDeleteStakefactorMutation,
	useUpdateStakefactorMutation,
	useUpdateStakefactorScenariosMutation,
	useCreateStakefactorLinkMutation,
	useDeleteStakefactorLinkMutation,
	useUpdateStakefactorLinkStrengthMutation,
} from '../../../graphql/generated'

import './Navigator.sass'
import HeaderPortal from 'layout/header/HeaderPortal'
import { getItemAlteredByScenario } from 'utils/rules'
import RadialVulnerabilityGauge from 'components/charts/RadialVulnerabilityGauge'
import useScreenSize from 'hooks/useScreenSize'
import { remainingSpace } from 'utils/layoutUtils'
import Legend from 'components/Radar/Legend'

const filterStakefactors = (stakefactors, filters, scenario) => {
	if (!stakefactors) return true

	// Get tags in search
	let tags = (filters.name.toLowerCase().match(/tag:[^, ]+/g) || []).map((t) => t.replace('tag:', ''))
	let search = filters.name.replace(/tag:[^, ]+/g, '').trim()

	let filteredStakefactors = stakefactors.filter((s: any) => {
		let matching = true
		if (search && s.name) {
			matching = s.name.toLowerCase().trim().match(search.toLowerCase())
		}

		/*
		if (s.tags) {
			for (let i = 0; i < tags.length; i++) {
				matching = matching && s.tags.includes(tags[i])
			}
		} else {
			matching = matching && tags.length == 0
		}*/

		return matching
	})

	if (filters.tag) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			return s.tags && s.tags.includes(filters.tag)
		})
	}

	if (filters.icon) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			if (!s.icon || s.icon == 'faUser') return filters.icon === 'faUser'
			return filters.icon === s.icon
		})
	}

	if (filters.indicator) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			return s.impactedIndicators?.map((ii: any) => ii.indicatorId).includes(filters.indicator)
		})
	}

	if (filters.constraint) {
		filteredStakefactors = filteredStakefactors.filter((s: any) => {
			return s.impactedConstraints?.map((ii: any) => ii.constraintId).includes(filters.constraint)
		})
	}

	if (scenario?.id) {
		filteredStakefactors = filteredStakefactors.map((s) => getItemAlteredByScenario(s, scenario))
	}

	return filteredStakefactors
}

export const Navigator = (props: any) => {
	const params: any = useParams()
	const { t } = useTranslation()

	// Stakefactor
	const [createStakefactor] = useCreateStakefactorMutation()
	const [updateStakefactor] = useUpdateStakefactorMutation()
	const [updateStakefactorScenarios] = useUpdateStakefactorScenariosMutation()
	const [updateStakefactorPosition] = useUpdateStakefactorPositionMutation()
	const [updateStakefactorIcon] = useUpdateStakefactorIconMutation()
	const [updateStakefactorImpact] = useUpdateStakefactorImpactMutation()
	const [updateStakefactorTags] = useUpdateStakefactorTagsMutation()
	const [updateStakefactorTrend] = useUpdateStakefactorTrendMutation()
	const [deleteStakefactor] = useDeleteStakefactorMutation()

	// Stakefactor links
	const [createStakefactorLink] = useCreateStakefactorLinkMutation()
	const [deleteStakefactorLink] = useDeleteStakefactorLinkMutation()
	const [updateStakefactorLinkStrength] = useUpdateStakefactorLinkStrengthMutation()

	const [editedStakefactor, setEditedStakefactor] = React.useState<any | null>(null)
	const [mode, setMode] = React.useState<any | null>({ name: 'initial' })
	const [isTransitioning, setIsTransitioning] = React.useState<any | null>(false)
	const [areAllLinksVisible, setAreAllLinksVisible] = React.useState<any | null>(true)
	const [tagFilter, setTagFilter] = React.useState<any | null>(null)
	const [iconFilter, setIconFilter] = React.useState<any | null>(null)
	const [indicatorFilter, setIndicatorFilter] = React.useState<any | null>(null)
	const [constraintFilter, setConstraintFilter] = React.useState<any | null>(null)

	const [opened, setOpened] = React.useState<true | false>(true)
	const [selectedTab, setSelectedTab] = React.useState<string>('general')
	const [selectedGlobalTab, setSelectedGlobalTab] = React.useState<string>('filters')

	const filteredStakefactors = filterStakefactors(
		props.stakefactors || [],
		{
			name: props.searchTerm,
			tag: tagFilter,
			icon: iconFilter,
			indicator: indicatorFilter,
			constraint: constraintFilter,
		},
		props.scenario
	)

	const updateTimer = React.useRef(null)
	React.useEffect(() => {
		if (!updateTimer.current) {
			setIsTransitioning(true)
		}
	}, [props.scenario])

	const screenSize = useScreenSize()
	let blocks = compact([
		{ id: 'header', verticalAlign: 'top', height: 70 },
		{ id: 'summary', horizontalAlign: 'left', width: 150 },
		// If we have "va" in url, we show the radar legend
		{ id: 'right', horizontalAlign: 'right', width: 390 },
		{ id: 'bottom', verticalAlign: 'bottom', height: 10 },
	])

	let remainingSpaceForCenter = remainingSpace({
		...screenSize,
		blocks,
	})

	// Enable / Disable left text close to radar
	let maxWidth = Math.min(remainingSpaceForCenter.width, remainingSpaceForCenter.height)
	let offsetX = (remainingSpaceForCenter.width - maxWidth) / 2 + remainingSpaceForCenter.x1
	let offsetY = (remainingSpaceForCenter.height - maxWidth) / 2

	// Help
	const [visibleHelpStep, setVisibleHelpStep] = React.useState<any | null>(null)
	const [isAmphiVisible, setIsAmphiVisible] = React.useState<any | null>(false)
	const closeHelp = () => setVisibleHelpStep(null)
	const initialStep = getInitialStep()
	const nextStep = getNextStep(visibleHelpStep)
	const onNext = () => setVisibleHelpStep(nextStep)
	const hasNext = !!nextStep

	const bodyHeight = window.innerHeight - 55
	const bodyWidth = window.innerWidth

	const allTags: any = uniq(
		flatten(
			(props.stakefactors || []).map((i: any) => {
				return i.tags || []
			})
		).map((i: string) => i.trim())
	).sort()

	const setAmphiVisible = (visible) => {
		//console.log('setAmphiVisible', visible, mode)
		//console.trace()
		if (!mode || mode.name !== 'linking') setIsAmphiVisible(visible)
	}

	const allIcons: any = uniq((props.stakefactors || []).map((i: any) => (i.icon ? i.icon : 'faUser'))).sort()

	const indexedStakefactors = keyBy(props.stakefactors || [], 'id')

	// Delete
	const handleDeleteStakefactor = async (id) => {
		await deleteStakefactor({
			variables: {
				id,
			},

			update(cache, { data: { deleteStakefactor } }) {
				cache.modify({
					id: `Project:${deleteStakefactor.id}`,
					fields: {
						stakefactors(existingRefs, { readField }) {
							return existingRefs.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactor: {
					__typename: 'Stakefactor',
					id: id,
				},
			},
		})

		props.deselect()
		props.refetch()
	}

	// Create
	const handleCreateStakefactorLink = async (origin, target) => {
		let result = await createStakefactorLink({
			variables: {
				projectId: params.id,
				originId: origin.id,
				targetId: target.id,
			},
			update(cache, { data: { createStakefactorLink } }) {
				cache.modify({
					id: `Stakefactor:${origin.id}`,
					fields: {
						links(existingStakefactorsLinksRef, { readField }) {
							const newStakefactorLink = cache.writeFragment({
								id: 'StakefactorLink:' + createStakefactorLink.id,
								data: createStakefactorLink,
								fragment: gql`
									fragment StakefactorFragment on Stakefactor {
										originId
										targetId
									}
								`,
							})
							return [...existingStakefactorsLinksRef, newStakefactorLink]
						},
					},
				})
			},

			optimisticResponse: {
				createStakefactorLink: {
					__typename: 'StakefactorLink',
					id: origin.id + '-' + target.id,
					originId: origin.id,
					targetId: target.id,
				},
			},
		})
	}

	// Delete
	const handleDeleteStakefactorLink = async (stakefactorId: string, id: string) => {
		console.log('handleDeleteStakefactorLink', id)

		await deleteStakefactorLink({
			variables: {
				projectId: params.id,
				id,
			},

			update(cache, { data: { deleteStakefactorLink } }) {
				cache.modify({
					id: `Stakefactor:${stakefactorId}`,
					fields: {
						links(existingLinksRef, { readField }) {
							return existingLinksRef.filter((ref: string) => id !== readField('id', ref))
						},
					},
				})
			},

			optimisticResponse: {
				deleteStakefactorLink: {
					__typename: 'StakefactorLink',
					id: id,
				},
			},
		})
	}

	const handleChangeStakefactorLinkStrength = (link: any, strength: number) => {
		console.log('handleChangeStakefactorLinkStrength', strength)

		updateStakefactorLinkStrength({
			variables: { id: link.id, strength },
			update(cache, { data: { updateStakefactorLinkStrength } }) {
				cache.modify({
					id: `StakefactorLink:${updateStakefactorLinkStrength.id}`,
					fields: {
						strength() {
							return strength
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorLinkStrength: {
					__typename: 'StakefactorLink',
					id: link.id,
					strength: strength,
					originId: link.originId,
					targetId: link.targetId,
				},
			},
		})
	}

	const handleChangeItemPosition = (index: number, x: any, y: any) => {
		console.log('handleChangeItemPosition', x, y)

		if (props.scenario) {
			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].x = x
			newScenarios[props.scenario.id].y = y

			optimisticlyUpdateScenario(props.stakefactors[index].id, 'Stakefactor', newScenarios)

			if (props.selection) props.select({ ...props.selection, x, y })
		} else {
			updateStakefactorPosition({
				variables: { id: props.stakefactors[index].id, x, y },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${props.stakefactors[index].id}`,
						fields: {
							x() {
								return x
							},
							y() {
								return y
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorPosition: {
						__typename: 'Stakefactor',
						id: props.stakefactors[index].id,
						x,
						y,
					},
				},
			})

			if (props.selection) props.select({ ...props.selection, x, y })
		}
	}

	const handleChangeItemIcon = (id: string, icon: any) => {
		console.log('handleChangeItemIcon', icon)

		updateStakefactorIcon({
			variables: { id: id, icon },
			update(cache) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						icon() {
							return icon
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorIcon: {
					__typename: 'Stakefactor',
					id,
					icon: icon,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, icon })
	}

	const handleChangeItemTags = (id: string, tags: string[]) => {
		console.log('handleChangeItemTags', tags)

		tags = uniq(tags.filter((t) => t && t.trim() != ''))

		updateStakefactorTags({
			variables: { id: id, tags },
			update(cache, { data: { updateStakefactorTags } }) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						tags() {
							return updateStakefactorTags.tags
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactorTags: {
					__typename: 'Stakefactor',
					tags,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, tags })
	}

	const handleChangeItemImpact = (id: string, impact: any) => {
		console.log('handleChangeItemImpact', impact)

		if (props.scenario) {
			let index = findIndex(props.stakefactors, (s: any) => s.id === id)
			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].impact = impact

			optimisticlyUpdateScenario(props.stakefactors[index].id, 'Stakefactor', newScenarios)

			if (props.selection) props.select({ ...props.selection, impact })
		} else {
			updateStakefactorImpact({
				variables: { id: id, impact },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${id}`,
						fields: {
							impact() {
								return impact
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorImpact: {
						__typename: 'Stakefactor',
						impact,
					},
				},
			})
		}

		if (props.selection) props.select({ ...props.selection, impact })
	}

	const optimisticlyUpdateScenario = (id, type, newScenarios) => {
		let method = {
			Stakefactor: updateStakefactorScenarios,
		}

		method[type]({
			variables: { id: id, scenarios: JSON.stringify(newScenarios) },
			update(cache) {
				cache.modify({
					id: `${type}:${id}`,
					fields: {
						scenarios() {
							return JSON.stringify(newScenarios)
						},
					},
				})
			},

			optimisticResponse: {
				[`update${type}Scenarios`]: {
					__typename: type,
					id,
					scenarios: JSON.stringify(newScenarios),
				},
			},
		})
	}

	const handleChangeItemTrend = (id: string, trend: any) => {
		console.log('handleChangeItemTrend', trend)

		if (props.scenario) {
			let index = findIndex(props.stakefactors, (s: any) => s.id === id)

			let newScenarios = {
				...(props.stakefactors[index].scenarios ? JSON.parse(props.stakefactors[index].scenarios) : {}),
			}
			if (!newScenarios[props.scenario.id]) newScenarios[props.scenario.id] = {}
			newScenarios[props.scenario.id].trend = trend

			optimisticlyUpdateScenario(id, 'Stakefactor', newScenarios)
		} else {
			updateStakefactorTrend({
				variables: { id: id, trend },
				update(cache) {
					cache.modify({
						id: `Stakefactor:${id}`,
						fields: {
							trend() {
								return trend
							},
						},
					})
				},

				optimisticResponse: {
					updateStakefactorTrend: {
						__typename: 'Stakefactor',
						id,
						trend,
					},
				},
			})
		}

		if (props.selection) props.select({ ...props.selection, trend })
	}

	const handleChangeItem = (id: string, values: any) => {
		let index = findIndex(props.stakefactors, (s: any) => s.id === id)

		updateStakefactor({
			variables: { id: props.stakefactors[index].id, ...values },
			update(cache, { data: { updateStakefactor } }) {
				cache.modify({
					id: `Stakefactor:${id}`,
					fields: {
						name() {
							return updateStakefactor.name
						},
						description() {
							return updateStakefactor.description
						},
					},
				})
			},

			optimisticResponse: {
				updateStakefactor: {
					__typename: 'Stakefactor',
					id,
					...props.stakefactors[index],
					...values,
				},
			},
		})

		if (props.selection) props.select({ ...props.selection, ...values })
	}

	const handleToggleTag = (t) => {
		if (props.searchTerm.match('tag:' + t)) {
			let newSearchTerm = props.searchTerm.replace('tag:' + t, '').trim()
			props.setSearchTerm(newSearchTerm)
		} else {
			props.setSearchTerm(props.searchTerm + ' tag:' + t)
		}
	}

	const handleChange = (action: string, value: string) => {
		//console.log('handleChange', action)
		setIsAmphiVisible(false)

		if (action == 'empty') props.select(null)

		if (!props.selection) return

		if (action == 'trend') {
			handleChangeItemTrend(props.selection?.id, value)
		} else if (action == 'impact') {
			handleChangeItemImpact(props.selection?.id, value)
		} else if (action == 'edit') {
			setEditedStakefactor(props.selection)
		} else if (action == 'link') {
			setMode({ name: 'linking', origin: props.selection })
		}
	}

	const select = async (item, callback) => {
		//console.log('select', item, 'mode', mode)
		//console.trace()
		if (mode.name == 'initial') {
			props.select(item, callback)
			setIsAmphiVisible(!!item)
		} else if (mode.name == 'linking') {
			if (item && item.id && mode.origin && mode.origin.id && item.id !== mode.origin.id) {
				setIsAmphiVisible(false)
				handleCreateStakefactorLink(mode.origin, item)
				props.select(null)
			} else if (item === null) {
				setIsAmphiVisible(false)
			}

			setMode({ name: 'initial' })
		}
	}

	return (
		<div className={'Navigator ' + mode.name + ' ' + (props.expanded ? 'expanded' : '')}>
			<HeaderPortal>
				{props.portalContent}
				<FontAwesomeIcon
					style={{ marginLeft: '0.5rem', marginRight: '0.5rem', cursor: 'pointer' }}
					icon={faInfoCircle}
					onClick={() => setVisibleHelpStep(initialStep)}
				/>
			</HeaderPortal>

			{maxWidth && (
				<div
					style={{
						position: 'absolute',
						height: `${maxWidth}px`,
						top: remainingSpaceForCenter.y1 + 'px',
						left: '0px',
					}}
				>
					<Legend marginTop={offsetY} height={maxWidth}></Legend>
				</div>
			)}
			<div
				className={`Body fw${remainingSpaceForCenter.width} x1_${remainingSpaceForCenter.x1} w${maxWidth} h${maxWidth} oX${offsetX} oY${offsetY}`}
				style={{
					position: 'absolute',
					width: `${maxWidth}px`,
					height: `${maxWidth}px`,
					top: remainingSpaceForCenter.y1 + 'px',
					left: offsetX + 'px',
				}}
			>
				{maxWidth && (
					<MainRadar
						offsetX={offsetX}
						offsetY={offsetY + 70}
						marginTop={offsetY}
						width={maxWidth}
						height={maxWidth}
						selection={props.selection}
						select={select}
						mode={mode}
						scenario={props.scenario}
						deselect={props.deselect}
						onChangeItemPosition={handleChangeItemPosition}
						onDeleteLink={handleDeleteStakefactorLink}
						children={filteredStakefactors}
						onChange={handleChange}
						displayAllLinks={areAllLinksVisible}
						isTransitioning={isTransitioning}
						isAmphiVisible={isAmphiVisible}
						setIsAmphiVisible={setAmphiVisible}
					></MainRadar>
				)}
			</div>
			<div className={`GaugeWrapper`}>
				<RadialVulnerabilityGauge
					stakefactors={props.stakefactors}
					scenario={props.scenario}
				></RadialVulnerabilityGauge>
			</div>
		</div>
	)
}

export default Navigator
