import { useState } from 'react'

import { useAuth0 } from '../../react-auth0-spa'
import { useSpring, animated, interpolate } from 'react-spring'

import { Layout } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faSignIn, faUser } from '@fortawesome/pro-light-svg-icons'
import { useHistory, useLocation } from 'react-router'

import './Header.sass'

const pagesMap: any = {
	'/projects': 'My decisions',
	'/profile': 'Profile',
}

export default function Header(props: any) {
	const { isAuthenticated, loginWithRedirect, logout } = useAuth0()
	const [opened, setOpened] = useState(false)

	let history = useHistory()
	let location = useLocation()

	let hasSubHeader: Boolean =
		!location.pathname ||
		(location.pathname !== '/projects' &&
			location.pathname !== '/' &&
			location.pathname !== '' &&
			location.pathname !== '/profile')

	let hasBack: Boolean = hasSubHeader || location.pathname === '/profile'

	/*
	const [subHeaderStyle, set, stop] = useSpring({
		height: hasBack ? 56 : 0,
		immediate: true,
  });*/

	const subHeaderStyle = useSpring({
		to: {
			transform: hasSubHeader ? 'translateY(0px)' : 'translateY(-56px)',
			overflow: 'hidden',
			height: '56px',
		},
		from: { transform: hasSubHeader ? 'translateY(-56px)' : 'translateY(0px)', overflow: 'hidden', height: '56px' },
	})

	/*const height = y.interpolate({
		map: Math.abs,
		range: [0, 1],
		output: [0, 56],
		extrapolate: 'clamp',
	});*/

	return (
		<animated.div style={{ zIndex: 2 }}>
			<Layout.Header
				style={{ position: 'fixed', zIndex: 1, width: '100%' }}
				className={props.visible ? 'Header visible' : 'Header notvisible'}
			>
				<>
					{hasBack && (
						<a
							onClick={() => {
								history.push('/projects')
							}}
							className="mr-2"
							style={{ marginRight: '1rem' }}
						>
							<FontAwesomeIcon icon={faChevronLeft} />
						</a>
					)}

					<div className="ml-0" style={{ width: '100%' }}>
						<div id="Header-root"></div>
					</div>
				</>
			</Layout.Header>
			<animated.div style={{ ...subHeaderStyle, position: 'fixed' }}>
				<Layout.Header className={props.visible ? 'SubHeader visible' : 'SubHeader notvisible'}>
					<div id="SubHeader-root"></div>
				</Layout.Header>
			</animated.div>
		</animated.div>
	)
}
