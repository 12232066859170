export default {
	project: {
		my_xs: 'My decisions',
		fields: {
			name: 'Title',
			name_explanation: 'Provide a title for your stakeholder management map',
			description: 'Goal',
			description_explanation: 'State your overall goal',
		},
		messages: {
			delete_confirmation: 'Delete project',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this project? This action can't be undone.",
			clone_confirmation: 'Clone stakeholder map',
			clone_confirmation_explanation: 'Are you sure you would like to clone this stakeholder map?',
		},
		actions: {
			edit: 'Edit details',
			delete: 'Delete project',
			clone: 'Clone project',
		},
	},
	option: {
		fields: {
			name_explanation: 'Please enter a name for this option',
		},
	},
	item: {
		name: 'Stakeholder',
		name_plural: 'Stakeholders',
		activeness_zones: {
			external_outer_positive: 'Neutral',
			external_inner_positive: 'Inactive',
			external_outer_negative: 'Neutral',
			external_inner_negative: 'Inactive',

			internal_low_positive: 'Low',
			internal_medium_positive: 'Medium',
			internal_high_positive: 'High',
			internal_low_negative: 'Low',
			internal_medium_negative: 'Medium',
			internal_high_negative: 'High',

			internal_positive: 'Catalysts',
			internal_negative: 'Inhibitors',
		},
		messages: {
			delete_confirmation: 'Delete stakeholder',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this stakeholder? This action can't be undone.",
		},
		fields: {
			links: {
				outcoming: {
					name: 'Outcoming links',
				},
				incoming: {
					name: 'Incoming links',
				},
			},
			indicators: {
				name: 'Impacts the following fundamentals',
			},
			constraints: {
				name: 'Is linked to the following constraints',
			},
		},
		actions: {
			create_new: 'Create a new stakeholder',
			edit: 'Edit details',
			delete: 'Delete stakeholder',
			clone: 'Clone stakeholder',
		},
	},
	item_link: {
		no_x: 'No link',
		actions: {
			create_new: 'Create new link',
		},
	},
	item_comment: {
		no_x: 'No comment',
		actions: {
			create_new: 'Create new comment',
		},
	},
	indicator: {
		no_x: 'No indicator',
		name: 'Fundamental',
		name_plural: 'Fundamentals',
		fields: {
			name: 'Success indicator',
			name_plural: 'Success indicators',
		},
		action: {
			add_x: 'Add an indicator',
		},
		message: {
			no_x: 'No indicator',
		},
		messages: {
			delete_confirmation: 'Delete indicator',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this indicator? This action can't be undone.",
		},
		actions: {
			delete: 'Delete indicator',
		},
	},
	constraint: {
		name: 'Constraint',
		name_plural: 'Constraints',
		no_x: 'No constraint',
		action: {
			add_x: 'Add an constraint',
		},
		message: {
			no_x: 'No constraint',
		},
		messages: {
			delete_confirmation: 'Delete constraint',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this constraint? This action can't be undone.",
		},
		actions: {
			delete: 'Delete constraint',
		},
	},
	scenario: {
		no_x: 'No snapshot',
		messages: {
			delete_confirmation: 'Delete snapshot',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this snapshot? This action can't be undone.",
		},
		actions: {
			create_new: 'Create a new snapshot from baseline',
			clone: 'Clone snapshot',
			edit: 'Edit snapshot',
			delete: 'Delete snapshot',
		},
	},
	fishbone_branch: {
		name: 'Fishbone branch',
		name_plural: 'Fishbone branch',
		messages: {
			delete_confirmation: 'Delete fishbone branch',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this branch? This action can't be undone.",
		},
	},
	fishbone_item: {
		name: 'Fishbone measure',
		name_plural: 'Fishbone measure',
		messages: {
			delete_confirmation: 'Delete fishbone measure',
			delete_confirmation_explanation:
				"Are you sure you would like to delete this measure? This action can't be undone.",
		},
	},
}
