import React from 'react'
import ReactDOM from 'react-dom'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import App from './App'
import reportWebVitals from './reportWebVitals'
import { history } from './utils/history'
import { Auth0Provider } from './react-auth0-spa'
import authConfig from './auth-config.json'
import { AuthorizedApolloProvider } from './authorized-apollo-client'
import './i18n'

// @ts-ignore
const config = authConfig[process.env.REACT_APP_MODE][process.env.REACT_APP_ENV]

const onRedirectCallback = async (url?: string) => {
	history.push(url ?? window.location.pathname)
}

if (document.location.host !== 'localhost:3000') {
	Sentry.init({
		dsn: 'https://97bbe638ea0d4a558b9d16486ece2c84@o23207.ingest.sentry.io/4504316114763776',
		integrations: [new BrowserTracing()],

		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.1,
	})
}

ReactDOM.render(
	<React.StrictMode>
		<AuthorizedApolloProvider>
			<App returnTo={config.returnTo} paymentPublicKey={config.paymentPublicKey} />
		</AuthorizedApolloProvider>
	</React.StrictMode>,
	document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
